/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import { FormattedProps } from '../types';
import {
  JaquettesGrid,
  Wrapper,
  CardPicture,
  SeeMoreIcon,
  SeeMoreWrapper,
  Label
} from '../styles/components';
import Title from 'shared/components/presentational/Title';
import Link from 'shared/components/presentational/Link';

type Props = FormattedProps;

export default function Jaquettes({
  title: { htmlTag, label },
  subtitle,
  jaquettes
}: Props) {
  const renderJaquette = React.useCallback(jaquette => {
    if (
      jaquette.type !== 'media' &&
      (jaquette.picture || jaquette.label) &&
      jaquette.link
    ) {
      return (
        <SeeMoreWrapper
          to={jaquette.link}
          key={jaquette.picture?.image || jaquette.label}
        >
          {jaquette.picture ? (
            <SeeMoreIcon
              url={jaquette.picture.image}
              alt={jaquette.picture.alt}
            />
          ) : null}
          {jaquette.label ? <Label>{jaquette.label}</Label> : null}
        </SeeMoreWrapper>
      );
    }

    if (!jaquette.picture) {
      return null;
    }

    if (jaquette.link) {
      return (
        <Link to={jaquette.link} key={jaquette.picture.image}>
          <CardPicture
            url={jaquette.picture.image}
            alt={jaquette.picture.alt}
          />
        </Link>
      );
    }
    return (
      <CardPicture
        url={jaquette.picture.image}
        alt={jaquette.picture.alt}
        key={jaquette.picture.image}
      />
    );
  }, []);

  return (
    <Wrapper data-testid="jaquette">
      <Title titleLabel={label} subtitle={subtitle} titleHtmlTag={htmlTag} />
      <JaquettesGrid>{jaquettes.map(renderJaquette)}</JaquettesGrid>
    </Wrapper>
  );
}
